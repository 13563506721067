import { CALCULATION_STATUS, DATA_TYPES, NODE_TYPES, REPORT_CALCULATION_IDS } from "api/constants";
import { toJS } from 'mobx';
import { portfolioIsNavBased } from "api/constants"
import { v4 as uuidv4 } from 'uuid';

export const arrayListsToArrayObjects = (payload) => {
    // go through each element and add all that are not arrays to
    let objItems = {}
    Object.keys(payload).forEach((payloadKey, index) => {
        const isNotAnArray = !Array.isArray(payload[payloadKey])
        if (isNotAnArray) {
            objItems[payloadKey] = payload[payloadKey]
        }
    })

    const filtered = Object.values(payload).filter((el) => Array.isArray(el));
    if (filtered.length === 0) {
        return [objItems]
    }


    const keys = Object.keys(payload).filter((key) =>
        Array.isArray(payload[key])
    );
    // TODO: add all elements that are not an array 
    const dataArray = [];

    filtered[0].forEach((listValue, listValueIndex) => {
        const newObj = {
            ...objItems,
        };
        keys.forEach((key, keyIndex) => {
            newObj[keys[keyIndex]] = filtered[keyIndex][listValueIndex];
        });
        dataArray.push(newObj);
    });
    return dataArray;
};

export const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const handleReduceToNumberByKey = (arr, key, decimals = 2) => {
    const accumulatedResult = arr.reduce((acc, item) => {
        return acc += item[key]
    }, 0)

    return parseFloat(accumulatedResult)
}

const nodeTypeToString = (nodeType) => {
    if (nodeType === 1) {
        return NODE_TYPES.PORTFOLIO
    } else if (nodeType === 2) {
        return NODE_TYPES.GROUP
    }

    return NODE_TYPES.ASSET
}

const nestByNodeType = (arr, groupBy) => {
    const rootNodeType = {};
    arr.forEach((el, i) => {

        if (el.NodeType === 1) {

            if (el.calculationsObject) {
                rootNodeType[el.keys] = el.calculationsObject[el.keys]
            } else {
                rootNodeType[el.keys] = el
            }

        } else if (el.NodeType === 2) {

            const newObj = { ...el.calculationsObject[el.keys], children: [] };

            if (rootNodeType[el.keys]) {
                // Groups exists allready
                if (rootNodeType[el.keys].children) {
                    rootNodeType[el.keys].children.push(newObj)
                } else {
                    rootNodeType[el.keys] = {
                        ...rootNodeType[el.keys],
                        children: []
                    }
                    rootNodeType[el.keys].children.push(newObj)
                }
            } else {
                rootNodeType[el.keys] = el.calculationsObject[el.keys]
            }

        } else {
            if (rootNodeType[el.keys] && rootNodeType[el.keys].children) {
                const child = rootNodeType[el.keys].children.find((group) => group.ID === el.ParentID);
                const newObj = el.calculationsObject[el.keys];
                if (child) {
                    if (child.children && child.children.length) {
                        child.children.push(newObj)
                    } else {
                        child.children = [newObj]
                    }
                }
            }

        }
    })

    return rootNodeType
}

const formatter = (arrPayload, settings, nestedSubtractKey = undefined, nestedSubArray = undefined) => arrPayload.map((el, index) => {

    const type = nodeTypeToString(el.NodeType || 1)
    const finalObject = {
        keys: settings.keys,
        type,
        NodeType: el.NodeType || 1,
        name: el.ID,
        ParentID: el.ParentID || el.ID,
    }
    // we also want to format maxDrawdown withing the element itself..
    if (el.CalculationStatus === CALCULATION_STATUS.BE_NOT_ASSIGNED) {
        // Add parentID
        return finalObject
    }

    let nestedArrFormatting = []
    // nestedSubtractKey is a value used, if we have a nested element inside an object of arrays that needs formatting
    // we then want to format that as well
    if (nestedSubtractKey && el[nestedSubtractKey] && el[nestedSubtractKey].length) {
        nestedArrFormatting = el[nestedSubtractKey].map((maxDDHistElement) => {
            const formatted = arrayListsToArrayObjects(maxDDHistElement);
            return {
                periods: maxDDHistElement.Number_Of_Periods,
                data: formatted
            };
        })

        // If a nested element is there, add to element before formatting arrays
        el[nestedSubtractKey] = nestedArrFormatting
    }

    let formattedElementArr = []
    // This is for nested arrays that contains the calculations. Some elements are on the same level
    // But in some cases the list is in a nested object
    if (nestedSubArray) {
        el[nestedSubArray].forEach((keyEl) => {
            const formattedKeyElem = arrayListsToArrayObjects(keyEl)
            if (Array.isArray(formattedKeyElem) && formattedKeyElem.length) {
                formattedElementArr.push(formattedKeyElem[0])
            } else {
                formattedElementArr.push(formattedKeyElem);
            }
        })

    } else {
        formattedElementArr = arrayListsToArrayObjects(el)
    }



    const objectified = {}


    formattedElementArr.forEach((arrElem) => {
        const elementKey = arrElem[settings.name]
        objectified[elementKey] = arrElem
    })

    finalObject.calculationsList = formattedElementArr.length > 0 ? formattedElementArr : [];
    finalObject.calculationsObject = objectified;

    return finalObject
})

export const handleCleanUpGlobalCalculations = (globalCalc, assumptionSettings, customerPortfolio) => {
    const formattedData = {};

    // Portfolio
    formattedData.Portfolio = {
        ID: "Portfolio",
        list: customerPortfolio,
        type: "Portfolio"
    }

    /* KEY_FIGURES */
    const keyFiguresArr = globalCalc.find(el => el.ID === REPORT_CALCULATION_IDS.KEY_FIGURES).KeyFigures
    const formattedKeyFiguresArr = formatter(keyFiguresArr, assumptionSettings.periods, "MaxDD_Hist")
    const groupedKeyFigures = nestByNodeType(formattedKeyFiguresArr, assumptionSettings.periods.name, "MaxDD_Hist")

    formattedData[REPORT_CALCULATION_IDS.KEY_FIGURES] = {
        ID: REPORT_CALCULATION_IDS.KEY_FIGURES,
        list: groupedKeyFigures,
        keys: assumptionSettings.periods.keys,
        type: DATA_TYPES.NESTED_PERIODS
    }

    /* RELATIVE_RISK_FIGURES */
    const relativeRiskFiguresArr = globalCalc.find(el => el.ID === REPORT_CALCULATION_IDS.RELATIVE_RISK_FIGURES).RelativeKeyFigures
    const formattedRelativeRiskFiguresArr = formatter(relativeRiskFiguresArr, assumptionSettings.periods)

    const groupedRelativeRiskFigures = nestByNodeType(formattedRelativeRiskFiguresArr, assumptionSettings.periods.name, "MaxDD_Hist")

    formattedData[REPORT_CALCULATION_IDS.RELATIVE_RISK_FIGURES] = {
        ID: REPORT_CALCULATION_IDS.RELATIVE_RISK_FIGURES,
        list: groupedRelativeRiskFigures,
        keys: assumptionSettings.periods.keys,
        type: DATA_TYPES.NESTED_PERIODS
    }

    // /* HISTORICAL_VAR */
    const historicalVaRArr = globalCalc.find(el => el.ID === REPORT_CALCULATION_IDS.HISTORICAL_VAR).HistoricalVaR

    const formattedHistoricalVaRArr = formatter(historicalVaRArr, assumptionSettings.confidenceInterval)
    const groupedHistoricalVaR = nestByNodeType(formattedHistoricalVaRArr, assumptionSettings.confidenceInterval.name)
    formattedData[REPORT_CALCULATION_IDS.HISTORICAL_VAR] = {
        ID: REPORT_CALCULATION_IDS.HISTORICAL_VAR,
        list: groupedHistoricalVaR,
        keys: assumptionSettings.confidenceInterval.keys,
        type: DATA_TYPES.NESTED_CONFIDENCE_INTERVALS
    }

    return formattedData
}

export const isRef = (item) => {
    if (item.key) {
        return true
    }

    return false
}

const handleSetPortfolioValues = (data) => {
    let portfolioItem = {}

    if (data.ESG && data.ESG.ESG_Portfolio) {
        portfolioItem = {
            ...data.ESG.ESG_Portfolio
        };
    }

    portfolioItem.PortfolioCurrency = data.PortfolioCurrency
    portfolioItem.PortfolioName = data.PortfolioName
    portfolioItem.PortfolioSubName = data.PortfolioSubName
    portfolioItem.PortfolioType = data.PortfolioType
    portfolioItem.AUM = data.AssetAmount + (data.CashAmount || 0)
    if(data.GroupResult && data.GroupResult.length) {
        portfolioItem.BenchMark = data.GroupResult[0].BenchMark
    }

    // Only include num of certifcates if port type is nav
    if (portfolioIsNavBased(data.PortfolioType)) {
        portfolioItem.NumberOfCertificates = data.NumberOfCertificates
        portfolioItem.NAV = data.NAV
    }

    // Add return data for entire portfolio 
    // portfolioItem = {
    //     ...portfolioItem,
    //     ...data.Return[0]
    // }

    return portfolioItem
}

const handleNonCalculatedAssets = (data) => {
    const errorData = [];
    data.forEach((el) => {
        
        if (el.NotIncludedIDs && el.NotIncludedIDs.length) {
            el.NotIncludedIDs.forEach((errorIsin, index) => {
                errorData.push({ id: errorIsin, CurrencyCode: el.NotIncludedIDsCurrencyCode[index], description: "Prices are missing. Go to the asset and add at least 2 prices to get a price approximation" });
            })
        }

        // We want to consistently use GroupID as our key
        // return { ...el, GroupID: el.Group_ID.toString() }
    })

    return errorData;
}

const flattenDataWithGroupSummary = (data, portfolioValues) => {
    const flattenedRows = [];
    const holdingsHierarchyKeys = [];

    // Extract portfolio-level information (first item)
    const portfolio = data[0];
    const portfolioId = portfolio.Group_ID;
    

    // Map each group in the portfolio-level GroupDetails to easily reference by ID
    const portfolioGroupInfo = {};
    portfolio.GroupDetails.forEach((group) => {
        portfolioGroupInfo[group.ID] = { Value: group.Value, Weight: group.Weight };
    });

    holdingsHierarchyKeys.push(portfolioId);
    // Add portfolio row
    flattenedRows.push({
        id: portfolioId,
        NodeType: portfolio.NodeType,
        Group_ID: portfolioId,
        RiskClassification: portfolio.RiskClassification,
        BenchMark: portfolio.BenchMark,
        Value: portfolioValues.AUM, // Portfolio-level values can be aggregated if needed
        Weight: null,
        parentId: null, // Top level has no parent
        CurrencyCode: portfolioValues.PortfolioCurrency,
        varaint: "portfolio",
        DisplayID: portfolioId,
    });

    // Process the remaining items in data (each representing a group with assets)
    data.slice(1).forEach((groupItem) => {
        const groupId = groupItem.Group_ID;
        holdingsHierarchyKeys.push(groupId);
        const groupInfo = portfolioGroupInfo[groupId] || {};

        // Add group row with combined portfolio summary values
        flattenedRows.push({
            id: groupId,
            DisplayID: groupId,
            NodeType: groupItem.NodeType,
            Group_ID: groupId,
            RiskClassification: groupItem.RiskClassification,
            BenchMark: groupItem.BenchMark,
            Value: groupInfo.Value || null, // Use portfolio-level Value if available
            Weight: groupInfo.Weight || null, // Use portfolio-level Weight if available
            parentId: portfolioId, // Link to portfolio as parent
            variant: "group",
        });

        // Add assets under each group
        groupItem.GroupDetails.forEach((asset) => {
            holdingsHierarchyKeys.push(asset.ID);
            flattenedRows.push({
                ...asset,
                GroupName: groupItem.groupId,
                id: asset.ID,
                DisplayID: asset.ID,
                name: asset.AssetName,
                NodeType: null, // Assets do not have NodeType
                Group_ID: groupId,
                AssetName: asset.AssetName,
                AssetType: asset.AssetType,
                Ticker: asset.Ticker,
                Sector: asset.Sector,
                SubSector: asset.SubSector,
                CurrencyCode: asset.CurrencyCode,
                Value: asset.Value,
                Weight: asset.Weight,
                RiskClassification: asset.RiskClassification,
                parentId: groupId, // Link to group as parent
                variant: "asset",
                BenchMark: asset.BenchMark,
            });
        });
    });

    return {flattenedRows, holdingsHierarchyKeys};
};

const mergeReturnsIntoPortfolio = (portfolioData, returnData) => {
    // Step 1: Create a lookup map for return data by ID
    const returnMap = returnData.reduce((acc, item) => {
        acc[item.ID] = item;
        return acc;
    }, {});

    // Step 2: Iterate over portfolio data and merge return fields
    const enrichedPortfolio = portfolioData.map((item) => {
        const returnInfo = returnMap[item.id] || returnMap[item.Group_ID];

        if (returnInfo) {
            // Add return fields to the item if return info exists
            return {
                ...item,
                Annual_Return: returnInfo.Annual_Return || null,
                Simple_Return: returnInfo.Simple_Return || null,
                TWR: returnInfo.TWR || null,
                MWR: returnInfo.MWR || null,
                Return_In_Kroner: returnInfo.Return_In_Kroner || null,
                PrimoHolding: returnInfo.PrimoHolding || null,
                UltimoHolding: returnInfo.UltimoHolding || null,
            };
        }

        // Return item as-is if no corresponding return info
        return item;
    });

    return enrichedPortfolio;
};

const mergeKeyTableWithGroupResult = (portfolioData, keyTableData) => {
    const keyTableMap = {};
  
    // Create a map for KeyTable groups by Group_ID
    keyTableData.forEach((group) => {
        keyTableMap[group.Group_ID] = group;
        if(group.GroupDetails) {
            group.GroupDetails.forEach((asset) => {
                keyTableMap[asset.ID] = asset;
            })
        }
    });

    // Merge each group in groupResult with matching group in KeyTable
    return portfolioData.map((group) => {


        const keyTableGroupDetails = keyTableMap[group.id];

        if(!keyTableGroupDetails) {
            return group
        }

        if(keyTableGroupDetails.NodeType === 1 || keyTableGroupDetails.NodeType === 2) {
            return group
        }

        // If no matching group in KeyTable, keep the original
        return {...group, ...keyTableGroupDetails};
    });
}

export const formatPortfolio = (portfolioWeightData) => {
    const hasGroupResult = portfolioWeightData.GroupResult && portfolioWeightData.GroupResult.length
    // const hasReturnData = portfolioWeightData.Return && portfolioWeightData.Return.length;
    const hasKeyFigures = portfolioWeightData.KeyTable && portfolioWeightData.KeyTable.length;


    let portfolioData = [];
    let errorData = [];
    let hHierarchyKeys = [];
    let portfolioValues = handleSetPortfolioValues(portfolioWeightData)
    if(hasGroupResult) {
        const {flattenedRows, holdingsHierarchyKeys} = flattenDataWithGroupSummary(portfolioWeightData.GroupResult, portfolioValues);
        portfolioData = flattenedRows;
        hHierarchyKeys = holdingsHierarchyKeys;

        // if(hasReturnData) {
        //     portfolioData = mergeReturnsIntoPortfolio(portfolioData, portfolioWeightData.Return);
        // }
        if(hasKeyFigures) {
            portfolioData = mergeKeyTableWithGroupResult(portfolioData, portfolioWeightData.KeyTable);
        }

        errorData = handleNonCalculatedAssets(portfolioWeightData.GroupResult)
    }

    return { data: portfolioData, errorData, portfolioOverview: portfolioValues, hHierarchyKeys }
}

export const normalize = (window.normalize = (data, keyRef) => {
    const items = {};
    const refs = []
    data.forEach((el) => {
        refs.push(el[keyRef])
        items[el.id] = el;
    })

    return { items, refs }
});

export const deNormalize = (window.deNormalize = (itemsObject, refsArray) => {
    return refsArray.map((key) => {
        return itemsObject[key]
    })
});

export const globalToJS = (window.globalToJS = (payload) => {
    return toJS(payload)
})

export const formatExportPortfolioToExcel = (portfolioList) => {
    const jsData = toJS(portfolioList)
    const assets = [];
    const exportedData = [];

    // Group everything based on variant
    for (let i = 0; i < jsData.length; i++) {
        let item = jsData[i];
        if (item.variant === "asset") {
            assets.push(item)
        }
    }

    for (let i = 0; i < assets.length; i++) {
        let asset = assets[i];
        if (i === 0) {
            const headers = Object.keys(asset).map((keyHeader) => {
                return keyHeader
            })

            exportedData.push(headers)
        }
        const values = Object.values(asset).map((value) => {
            return value
        })

        exportedData.push(values)


    }
    return exportedData;
}

const refactoredWeightHelper = (dataSet, postfixLabel = null, nameKey = null) => {
    let miscCategory = [];
    if(dataSet.GroupDetails.length > 10) {
        miscCategory = dataSet.GroupDetails.filter((el) => el.Weight <= 0.8)
    }

    const sortedBySizeData = dataSet.GroupDetails.filter((el) => el.Weight > 0.8).sort((a, b) => b.Value -a.Value)
    
    // Format the items to fit into a chart
    const formatted = sortedBySizeData.map((element, i) => {
        
        const item = {
            key: `${postfixLabel ? postfixLabel : ""} ${element.ID}`,
            name: nameKey ? element[nameKey] : `${postfixLabel ? postfixLabel : ""} ${element.ID}`,
            value: element.Weight,
        }

        if(element.Value) {
            item.secondaryValue = element.Value
        }

        return item;
    })

    // No last items to return the array as is
    if(!miscCategory.length) {
        return formatted;
    }

    // Group the last items together
    let Value = 0;
    let Weight = 0;
    miscCategory.forEach((element) => {
        if(element.Value) {
            Value += element.Value;
        }
        Weight += element.Weight;
    })
    const groupedItem = {
        key: `${postfixLabel ? postfixLabel : ""} *Andre`,
        name: `${postfixLabel ? postfixLabel : ""} *Andre`,
        value: Weight,
    }
    
    if(Value) {
        groupedItem.secondaryValue = Value
    }
    
    formatted.push(groupedItem)
    return formatted;
}

const getChartAndTableFromWeights = (dataCopy, postfixLabel, nameKey) => {
    let dataSet;
    let chart = [];
    let table = [];
    let errorData = [];

    dataCopy.forEach((el, index) => {
        // If first element, we want to construct the chart, based on that dataset
        if(index === 0) {
            dataSet = el;
            if(!dataSet || !dataSet.GroupDetails || !dataSet.GroupDetails.length) {
                return;
            }
            chart = refactoredWeightHelper(dataSet, postfixLabel, nameKey)
        } else {
            
            if(el.NotIncludedIDs && el.NotIncludedIDs.length) {
                el.NotIncludedIDs.forEach((errorIsin, index) => {
                    errorData.push({ id: errorIsin, Currency: el.NotIncludedIDsCurrencyCode[index], description: "Missing prices"  });
                })
            }

            if(!el.GroupDetails || el.GroupDetails.length === 0) {
                return;
            }

            el.GroupDetails.forEach((asset) => {
                const item = {
                    ...asset,
                    Group_ID: el.Group_ID,
                    id: uuidv4(),
                }

                table.push(item)
            })
        }
    })

    return { chart, table }
}

export const handleFormatWeights = (resultArray, postfixLabel, nameKey) => {
    let dataCopy = resultArray;

    // Make sure data is there
    if(!dataCopy || dataCopy.length === 0) {
        return [];
    }

    return getChartAndTableFromWeights(dataCopy, postfixLabel, nameKey)
}

export const handleFormatAllocations = (allocations) => {
    const topElements = [];
    const chartAndTableData = []

    allocations.forEach((allocation, index) => {
        const item = getChartAndTableFromWeights(allocation);
        topElements.push(allocation[0]);
        chartAndTableData.push(item);
    })

    const formatted = topElements.map((chart, index) => {
        const allocationItem = {
            AllocationName: chart.AllocationName,
            id: chart.AllocationID,
            chart: chartAndTableData[index].chart,
            table: chartAndTableData[index].table,
            valuePostFix: "%",
            dataKey: "value",
            nameKey: "name"
        }

        return allocationItem
    })

    const mapped = formatted.reduce((acc, item) => acc.set(item.id, item), new Map());

    return {
        data: formatted,
        mapped, 
    }
}

const formatDistributionDataSet = (dataSet) => {
    if(!dataSet.GroupDetails) {
        dataSet.GroupDetails = []
    }
    const sortedBySizeData = dataSet.GroupDetails
        .sort((a, b) => b.Value - a.Value)
        .map(element => ({
            key: element.ID,
            name: element.ID,
            value: element.Weight,
            secondaryValue: element.Value || undefined
        }));

    return sortedBySizeData;
}

export const handleFormatDistributionData = (distributionDataList, groupKey) => {
    const data = formatDistributionDataSet(distributionDataList);
    const name = distributionDataList[groupKey]
    return { data, name }
}

export const handleFormatAllocationData = (allocationDataList, groupKey, categoryKey, allocationIdKey) => {
    const data = formatDistributionDataSet(allocationDataList);
    const name = allocationDataList[groupKey]
    const category = allocationDataList[categoryKey]
    const id = allocationDataList[allocationIdKey]
    return { data, name, category, id }
}