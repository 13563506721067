/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Typography, Tooltip as MuiTooltip, TextField, tooltipClasses } from '@mui/material';
import { portfolioIsTransactionsBased } from 'api/constants';
import { isArray, isNumber, isString } from "utils/typeChecker"
import { numberWithCommas } from "utils/numberManipulators"
import { formattedDateToString, dateToInt, now } from "utils/dateFormatters"
import { RowMenu } from "./RowMenus";

import { styled } from '@mui/material';

const HtmlTooltip = styled(({ className, ...props }) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));


const handleChangeValueInput = (props) => (e) => {
    props.api.setEditCellValue({ id: props.id, field: props.field, value: e.target.value });
    props.api.commitCellChange({ id: props.id, field: props.field });
    props.api.commitRowChange(props.id);
}

const currencyFormatter = (currencyCode) => {
    switch(currencyCode) {
        case "DKK":
            return "Kr."
        case "USD":
            return "$"
        case "EUR":
            return "€"
        default:
            return currencyCode
    }
}

const getFormattedMoneyValue = (value, currencyCode, decimals = 2) => {
    if(!value || !isNumber(value)) {
        return ""
    }
    return `${numberWithCommas(value, decimals)} ${currencyFormatter(currencyCode)}`
}

const getFormattedPercentageValue = (value, decimals = 2) => {
    if(!value || !isNumber(value)) {
        return ""
    }
    return `${numberWithCommas(value, decimals)}%`
}

const getBaseColumns = () => {
    return [
        {
            field: "DisplayID", 
            headerName: "Identifier", 
            hideable: false,
            minWidth: 200,
            display: "flex",
        },
        { 
            field: 'name', 
            display: "flex", 
            hideable: false, 
            headerName: 'Name', 
            minWidth: 200 ,
        },
        { 
            field: 'CurrencyCode', 
            headerName: 'Currency',
            minWidth: 100,
            display: "flex",
        },
    ]
}

export const getKeyFigColumns = (isStandAlone) => {
    let keyFigColumns = []

    if(isStandAlone) {
        keyFigColumns = [...getBaseColumns()]
    }

    keyFigColumns = [...keyFigColumns,
        { field: 'FXRateDate', headerName: 'Fx rate date', renderCell: (props) => {
            return props.value ? formattedDateToString(props.value) : ""
        }  },
        { field: 'Mean', headerName: 'MEAN', renderCell: (props) => {
            let value = ""
            if(isNumber(props.value)) {
                value = numberWithCommas(props.value)
            }
        
            return value
        }  },
    { field: 'Standard_Deviation', headerName: 'Std. deviation', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
    
        return value
    }  },
    { field: 'Sharpe_Ratio', headerName: 'Sharpe ratio', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
    
        return value
    }  },
    { field: 'Adjusted_Sharpe_Ratio', headerName: 'Adj. sharpe ratio', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
    
        return value
    }  },
    { field: 'Max_DD', headerName: 'Max dd', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
    
        return value
    }  },
    { field: 'DrawDown_Duration', headerName: 'Dd duration', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
    
        return value
    }  },
    { field: 'Sortino', headerName: 'Sortino', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
    
        return value
    }  },
    { field: 'Omega', headerName: 'Omega', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
    
        return value
    }  },
    { field: 'Alpha', headerName: 'Alpha', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
    
        return value
    }  },
    { field: 'Beta', headerName: 'Beta', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
    
        return value
    }  },
    { field: 'Information_Ratio', headerName: 'Information ratio', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
    
        return value
    }  },
    
    { field: 'Tracking_Error', headerName: 'Tracking error', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
    
        return value
    }  },
    { field: 'Risk_Adjusted_Return', headerName: 'Risk adj. return', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
        return value
    }  },
    { field: 'VaR_Normal', headerName: 'VaR', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
        return value
    }  },
    { field: 'VaR_In_Kroner_Normal', headerName: 'VaR Kr.', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
    
        return value
    }  },
    { field: 'CVaR_Normal', headerName: 'CVaR', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
    
        return value
    }  },
    { field: 'CVaR_In_Kroner_Normal', headerName: 'CVaR Kr.', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
    
        return value
    }  },
    { field: 'VaR_Hist', headerName: 'VaR hist.', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
    
        return value
    }  },
    { field: 'VaR_In_Kroner_Hist', headerName: 'VaR Kr. hist.', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
    
        return value
    }  },
    { field: 'CVaR_Hist', headerName: 'CVaR hist.', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
    
        return value
    }  },
    { field: 'CVaR_In_Kroner_Hist', headerName: 'CVaR Kr. hist.', renderCell: (props) => {
        let value = ""
        if(isNumber(props.value)) {
            value = numberWithCommas(props.value)
        }
        return value
    }  },
    ]

    keyFigColumns = keyFigColumns.map((col)=>{
        return {
            ...col,
            cellClassName: (params) => {
                return `keyfigure-column-cell`
            },
            groupType: "keyfigs"
        }
    })

    return keyFigColumns;
}

const renderNumber = (props) => {
    if(isNumber(props.value)) {
        return numberWithCommas(props.value, 2)
    }
    return props.value
}

const renderPercentageNumber = (props) => {
    if(isNumber(props.value)) {
        return numberWithCommas(props.value, 2) + "%"
    }
    return props.value
}

export const getReturnColumns = (IsHolding, IsExpanded) => {
    let transactionColumns = [
        { field: 'ID', headerName: 'Identifier' },
        { field: 'name', headerName: 'Name' },
    ];

    if(IsExpanded && !IsHolding) {
        transactionColumns = [
            ...transactionColumns, 
            { field: 'PrimoHolding', headerName: 'Primo holding', align: "right", type: "number", renderCell: renderNumber },
            { field: 'UltimoHolding', headerName: 'Ultimo holding', align: "right", type: "number", renderCell: renderNumber },
        ] 
    }

    transactionColumns = [
        ...transactionColumns,
        { field: 'PrimoValue', headerName: 'Primo value', align: "right", type: "number", renderCell: renderNumber },
        { field: 'Return_In_Kroner', headerName: 'Return in Kr.', align: "right", type: "number", renderCell: renderNumber },
        { field: 'Simple_Return', headerName: 'Simple return', align: "right", type: "number", renderCell: renderPercentageNumber },
        { field: 'Annual_Return', headerName: 'Annual return', align: "right", type: "number", renderCell: renderPercentageNumber },
    ]

    if(!IsHolding) {
        transactionColumns = [
            ...transactionColumns,
            { field: 'MWR', headerName: 'MWR', align: "right", type: "number", renderCell: renderNumber },
            { field: 'TWR', headerName: 'TWR', align: "right", type: "number", renderCell: renderNumber },
        ]
    }

    transactionColumns = [
        ...transactionColumns,
        { field: 'KR_Asset', headerName: 'Kr. Asset', align: "right", type: "number", renderCell: renderNumber },
        { field: 'KR_Currency', headerName: 'Kr. currency', align: "right", type: "number", renderCell: renderNumber },
        { field: 'SR_Asset', headerName: 'SR asset', align: "right", type: "number", renderCell: renderNumber },
        { field: 'SR_Currency', headerName: 'SR currency', align: "right", type: "number", renderCell: renderNumber },
        { field: 'Account_Fee', headerName: 'Account fee', align: "right", type: "number", renderCell: renderNumber },
        { field: 'Account interest', headerName: 'Account interest', align: "right", type: "number", renderCell: renderNumber },
        { field: 'AfkastSkat', headerName: 'Return tax', align: "right", type: "number", renderCell: renderNumber },
        { field: 'CA_Brutto', headerName: 'CA brutto', align: "right", type: "number", renderCell: renderNumber },
        { field: 'CA_Cost', headerName: 'CA cost', align: "right", type: "number", renderCell: renderNumber },
        { field: 'CA_Netto', headerName: 'CA netto', align: "right", type: "number", renderCell: renderNumber },
        { field: 'CA_Tax', headerName: 'CA tax', align: "right", type: "number", renderCell: renderNumber },
        { field: 'Emission_Fee', headerName: 'Emission fee', align: "right", type: "number", renderCell: renderNumber },
        { field: 'Management_Fee', headerName: 'Management fee', align: "right", type: "number", renderCell: renderNumber },
        { field: 'Netto_Cash', headerName: 'Netto cash', align: "right", type: "number", renderCell: renderNumber },
        { field: 'Relaliseret_Kroner', headerName: 'Realized Kr.', align: "right", type: "number", renderCell: renderNumber },
        { field: 'Urealiseret_Kroner', headerName: 'Unrealized Kr.', align: "right", type: "number", renderCell: renderNumber },
        { field: 'Total_CashIn', headerName: 'Total Cash in', align: "right", type: "number", renderCell: renderNumber },
        { field: 'Total_CashOut', headerName: 'Total cash out', align: "right", type: "number", renderCell: renderNumber },
        { field: 'Total_Cost', headerName: 'Total cost', align: "right", type: "number", renderCell: renderNumber },
        { field: 'Trade_Cost', headerName: 'Trade cost', align: "right", type: "number", renderCell: renderNumber },
    ];

    return transactionColumns;
}


export const getColumns = (portfolioType) => {
    let columns = [
        ...getBaseColumns(),
        { 
            field: 'Holding', 
            headerName: 'Holding', 
            editable: true, 
            align: "right",
            renderCell: (props) => {
                return numberWithCommas(props.value, 2)
            } , 
            renderEditCell: (props) => {
                return (
                    <TextField value={props.value} onChange={handleChangeValueInput(props)} fullWidth />
                )
            }
        },
        { 
            field: 'AssetCleanPrice', 
            headerName: 'Price', 
            align: "right", 
            renderCell: (props) => {
                return numberWithCommas(props.value, 3)
            }
        },
        { field: 'AssetDirtyPrice', headerName: 'Price + Accrued IR', align: "right",  renderCell: (props) => {
            return numberWithCommas(props.value, 3)
        } ,  },
        { field: 'PriceDate', headerName: 'Price date', renderCell: (props) => {
            return props.row.variant === "asset" ? formattedDateToString(props.value) : ""
        } , },
        {
            field: 'FXRate',
            headerName: 'Fx rate',
            align: "right",
            renderCell: (props) => {
                let value = ""
                if(props.value) {
                    value = isArray(props.value) ? props.value[0].toFixed(5).replaceAll(".", ",") : isNumber(props.value) ? props.value.toFixed(3).replaceAll(".", ",") : ""
                }
                return value
                
            } },
        { field: 'Value', headerName: 'AUM', align: "right", renderCell: (props) => {
            return numberWithCommas(props.value, 2)
        } ,   },
        { 
            field: 'BenchMark', 
            minWidth: 200,  
            headerName: 'Benchmark', 
            editable: false, 
            renderCell: (params) => {

                if(!params.row.BenchMark) {
                    return ""
                }

                if(isString(params.row.BenchMark)) {
                    return `${params.row.BenchMark}: 100%`
                }

                if(!isArray(params.row.BenchMark) || params.row.BenchMark.length === 0) {
                    return ""

                }

                const normalizedBenchmarksList = params.row.BenchMark.map((be) => {
                    if(be === 0) {
                        return { label: "None", weight: 0 }
                    }

                    const [label, weight] = be;
                    return { label, weight };
                });
                const { label, weight } = normalizedBenchmarksList[0];
                return (
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                {normalizedBenchmarksList.map(({label, weight}, index) => {
                                    return (
                                        <>
                                            <Typography key={index}>{label}: {weight}</Typography>
                                            <br />
                                        </>
                                    )
                                })}
                            </React.Fragment>
                        }
                    >
                        <>{`${label}: ${weight}% ${normalizedBenchmarksList.length > 1 ? ", and" + normalizedBenchmarksList.lenth - 1 + " more"  : ""}`}</>
                    </HtmlTooltip>
                )
        }},
        { field: 'Weight', headerName: 'Weight', align: "right", renderCell: (props) => {
            return isNumber(props.value) ? numberWithCommas(props.value, 2) : props.value
        } },
        { field: 'WeightTotal', headerName: 'Weight total', align: "right", renderCell: (props) => {
            return isNumber(props.value) ? numberWithCommas(props.value, 2) : props.value
        } },
        { field: 'RiskClassification', headerName: 'Risk class',align: "right", renderCell: ({ value, row }) => numberWithCommas(value, row.variant === "asset" ? 0 : 2) },
        { field: 'Sector', headerName: 'Sector' },
        { field: 'Ticker', headerName: 'Ticker' },
        { field: 'AssetType', headerName: 'Type' },
        { field: 'LiquidityRatio', headerName: 'Liquidity ratio',align: "right", renderCell: (props) => {
            return isNumber(props.value) ? numberWithCommas(props.value, 2) : props.value
        }},
        { field: "actions", hideable: false, headerName: '', renderCell: (props) => {
            return (
                <RowMenu data={props.row} apiRef={props.api} />
            )
    } }]

    if(portfolioIsTransactionsBased(portfolioType)) {
        columns.push({ field: 'TradedPrice', headerName: 'Traded price',align: "right",  renderCell: (props) => {
            return numberWithCommas(props.value, 2)
        }})
        columns.push({ field: 'Traded_FXRate', headerName: 'Traded FX rate',align: "right",  renderCell: (props) => {
            return numberWithCommas(props.value, 2)
        } ,  })
        columns.push({ field: 'StatusDate', headerName: 'Status date', renderCell: (props) => {
            return props.value ? formattedDateToString(props.value) : ""
        } })
    }

    columns = columns.map((col)=>{
        if (col.field === "actions") {
            return col;
        }
        
        return {
            ...col,
            groupType: "overview"
        }
    });

    return [...columns, ...getReturnColumns(portfolioType), ...getKeyFigColumns()];
}
