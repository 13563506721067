import { numberWithCommas } from 'utils/numberManipulators';

export function calculateInterval(data) {
    if(!data || data.length === 0) {
        return 0
    }
    const length = data.length;
    const interval = Math.round(length / 12);
    return interval > 0 ? interval : 1;
}

export const domain = [dataMin => {
    return dataMin - (dataMin * 0.01)
}, dataMax => {
    return dataMax * 1.01
}]

export const setNumOfIntervalLabels = (dataLength, numberOfLabels) => {
    return Math.ceil(dataLength / numberOfLabels) - 1;
};

export const getValueInUnit = (value, unit) => {
    if(unit === "mio") {
        return numberWithCommas((value / 1000000), 0)
    } else if(unit === "k") {
        return numberWithCommas((value / 1000), 0)
    } else if (unit === "%") {
        return numberWithCommas(value, 2)
    }

    return numberWithCommas(value)
}
