import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import React from 'react';


const StyledCardContent = styled(Box)((props) => ({
    padding: props.theme.spacing(props.padding || 0),// props.theme.spacing(props.padding >= 0 ? props.padding : 2),
    display: "flex",
    flex: 1,
    flexDirection: "column"
}))

const CardContent = (props) => {
    return (
        <StyledCardContent padding={props.padding} style={props.style}>
            {props.children}
        </StyledCardContent>
    );
}

CardContent.propTypes = {
    children: PropTypes.node,
    padding: PropTypes.number,
    style: PropTypes.object
}
 
export default CardContent;