import { Box } from '@mui/material';
import PropTypes from "prop-types";
import React from 'react';
import { styled } from '@mui/material/styles';

const StyledCardHeader = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    padding: theme.spacing(1),
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
    whiteSpace: "nowrap",
}))

const CardHeader = (props) => {
    return (
        <StyledCardHeader>
            {props.children}
        </StyledCardHeader> 
    );
}

CardHeader.propTypes = {
    children: PropTypes.node,
}
 
export default CardHeader;