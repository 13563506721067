/* eslint-disable react/prop-types */
import React from 'react';
import { Divider, Box, Paper, styled } from '@mui/material';
import PropTypes from "prop-types";
import CardHeadline from "./CardHeadline";
import CardHeader from "./CardHeader";
import CardContent from "./CardContent";
import CardFooter from "./CardFooter";

const StyledCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1), // theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
}))

const Card = ({ id, headline, renderActions, children, renderFooter, dataExport, style, padding }) => {
    return (
        <StyledCard id={id} style={style}>
            {(headline || renderActions) && (
                <>
                    <CardHeader>
                        {headline && (
                            <CardHeadline headline={headline} />
                        )}
                        {renderActions && (
                            <Box marginLeft={"auto"} display={"flex"} alignItems={"center"}>
                                {renderActions(dataExport)} 
                            </Box>
                        )}
                    </CardHeader>
                </>
            )}
            
            <CardContent padding={padding}>
                {children}
            </CardContent>
            {renderFooter && (
                <CardFooter>
                    {renderFooter()} 
                </CardFooter>
            )}
            
        </StyledCard>
    );
}


Card.propTypes = {
    headline: PropTypes.string,
    children: PropTypes.node,
    renderActions: PropTypes.func,
    renderFooter: PropTypes.func,
    dataExport: PropTypes.array,
}
 
export default Card;