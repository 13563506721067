import * as React from 'react';
import { DataGridPremium, gridClasses } from '@mui/x-data-grid-premium';
import { styled, lighten, alpha } from '@mui/material/styles';
import { PropTypes } from 'prop-types';
import { toJS } from 'mobx';
import { ROW_HEIGHT } from './constants';


const StyledDataGrid = styled(DataGridPremium)(({ theme }) => {
  const borderColor = lighten(alpha(theme.palette.divider, 1), 1);
  const selectedCellBorder = alpha(theme.palette.primary.main, 1);

  return {
    [`& .${gridClasses.cell}`]: {
      border: `1px solid rgba(0,0,0,0.1) `,
      borderRight: `1px solid ${borderColor}`,
      borderBottom: `1px solid ${borderColor}`,
    },
    [`& .${gridClasses.columnHeaders}`]: {
      minHeight: `${ROW_HEIGHT}px !important`,
      maxHeight: `${ROW_HEIGHT}px !important`,
      backgroundColor: alpha(theme.palette.primary.dark, 0.1)
    },
    [`& .${gridClasses.columnHeader}`]: {
      minHeight: `${ROW_HEIGHT}px !important`,
      maxHeight: `${ROW_HEIGHT}px !important`,
      backgroundColor: alpha(theme.palette.primary.dark, 0.1)
    },
    [`& .${gridClasses.cell}.Mui-selected`]: {
      borderColor: alpha(theme.palette.primary.main, 0.1),
    },
    [`& .${gridClasses.cell}--editable`]: {
      backgroundColor: alpha(theme.palette.primary.light, 0.1),
    },
    [`& .${gridClasses.cell}--not-allocated`]: {
      backgroundColor: alpha(theme.palette.error.main, 0.3),
    },
    [`& .${gridClasses.cell}.Mui-selected.${gridClasses['cell--rangeTop']}`]: {
      borderTopColor: selectedCellBorder,
    },
    [`& .${gridClasses.cell}.Mui-selected.${gridClasses['cell--rangeBottom']}`]: {
      borderBottomColor: selectedCellBorder,
    },
    [`& .${gridClasses.cell}.Mui-selected.${gridClasses['cell--rangeLeft']}`]: {
      borderLeftColor: selectedCellBorder,
    },
    [`& .${gridClasses.cell}.Mui-selected.${gridClasses['cell--rangeRight']}`]: {
      borderRightColor: selectedCellBorder,
    },
  };
});

const RebusExcelDataGrid = ({ rows, columns, loading = false, onCellDoubleClick, apiRef, onCellEditStop, onRowEditStop, onCellKeyDown, id, initialPinnedColumns, processRowUpdate, getRowId, editMode, onCellClick }) => {
  const rowsData = toJS(rows)
  return (
    <StyledDataGrid
      id={id}
      onCellClick={onCellClick}
      rows={rowsData}
      columns={columns}
      apiRef={apiRef}
      loading={loading}
      rowHeight={ROW_HEIGHT}
      columnBuffer={8}
      columnThreshold={1}
      unstable_cellSelection
      editMode={editMode || 'cell'}
      onCellEditStop={onCellEditStop}
      onRowEditStop={onRowEditStop}
      processRowUpdate={processRowUpdate}
      onCellDoubleClick={onCellDoubleClick}
      
      
      
      getRowClassName={(params) => {
        if(params.row.inPortfolioButNotAllocated) {
          return `${gridClasses.cell}--not-allocated`
        }
      }}
      getRowId={getRowId}
      onCellKeyDown={onCellKeyDown}
      initialState={{
        pinnedColumns: initialPinnedColumns || { right: [], left: [] },
      }}
      disableColumnMenu
      // disableColumnReorder
      disableChildrenSorting
      disableColumnSorting
      disableMultipleColumnsSorting
      hideFooter
    />
  );
}

RebusExcelDataGrid.propTypes = {
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    loading: PropTypes.boolean,
    apiRef: PropTypes.object,
    onCellEditStop: PropTypes.func,
    rowModesModel: PropTypes.object,
    onRowModesModelChange: PropTypes.func,
    onRowEditStop: PropTypes.func,
    onCellKeyDown: PropTypes.func,
    id: PropTypes.string,
    initialPinnedColumns: PropTypes.object,
    processRowUpdate: PropTypes.func,
    getRowId: PropTypes.func,
    editMode: PropTypes.string,
    onCellClick: PropTypes.func,
    onCellDoubleClick: PropTypes.func,
}

export default RebusExcelDataGrid;
