import { observable, makeObservable, action, runInAction } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { APIS } from "api/constants";
import { mobXStoreHelper } from "utils/mobXStoreHelper";
import { mergeArrays } from "utils/arrayHelpers";
import dataStore from "stores/dataStore";
import snackbarStore from "stores/snackbarStore";
import { goYearsBack, goDaysBack } from "utils/dateFormatters";
import call from "api/call";

const translateErrorMessage = (errorMessage) => {
    switch (errorMessage) {
        case "Error: 1":
            return "No data to obtain";
        case "Error: 2":
            return "The start-date lies before there is data on the asset";
        case "Error: 3":
            return "The asset has matured";
    }
};

class PortfolioReturnStore {
    loading = false;
    error = null;
    AllRows = [];
    DisplayRows = [];
    columns = [];
    IsHolding = false;
    IsExpanded = false;
    FromDate = null;
    ToDate = dataStore.calcDate;
    earliestFromDate = null;
    latestFromDate = null;
    earliestToDate = null;
    latestToDate = null;
    NonIncludedAssets = [];
    ReturnTable = [];
    calculatedStartDate = null;
    calculatedEndDate = null;

    constructor() {
        makeObservable(this, {
            loading: observable,
            error: observable,
            AllRows: observable,
            DisplayRows: observable,
            columns: observable,
            IsHolding: observable,
            IsExpanded: observable,
            FromDate: observable,
            ToDate: observable,
            earliestFromDate: observable,
            latestFromDate: observable,
            earliestToDate: observable,
            latestToDate: observable,
            NonIncludedAssets: observable,
            ReturnTable: observable,
            setReturnDate: action.bound,
            fetchLatestTransactionDate: action.bound
        });
    }

    toggleIsExpanded(newValue, CustomerID, PortfolioName) {
        this.IsExpanded = newValue;
        this.DisplayRows = this.IsHolding || !this.IsExpanded ? this.AllRows : this.AllRows.filter((el) => {
            return (
                (el.UltimoHolding && el.UltimoHolding > 0) ||
                el.variant === "group" ||
                el.variant === "portfolio"
            );
        });

    }

    async fetchLatestTransactionDate(CustomerID, PortfolioName) {
        mobXStoreHelper(APIS.GET_LATEST_TRANSACTION_DATE, { CustomerID: Number(CustomerID), PortfolioName });
    }

    async calcPortfolioReturn(CustomerID, PortfolioName) {
        runInAction(() => {
            this.loading = true;
            this.calculatedStartDate = null;
            this.calculatedEndDate = null;
        })
        const res = await call(APIS.GET_LATEST_TRANSACTION_DATE, { CustomerID: Number(CustomerID), PortfolioName });
        runInAction(() => {
            this.earliestFromDate = res.message.FirstTransactionDate;
            this.latestFromDate = goDaysBack(this.ToDate, 1);
            this.earliestToDate = res.message.FirstTransactionDate;
            this.latestToDate = dataStore.calcDate;
            
            if (this.FromDate === null) { // if from date has not been set yet (first load)
                this.FromDate = goYearsBack(this.ToDate, 1);
            }

            if(this.FromDate < this.earliestFromDate) {
                this.FromDate = this.earliestFromDate;
            }
        })
        
        const params = {
            CustomerID: Number(CustomerID),
            PortfolioName,
            CalculationDate: this.ToDate,
            StartDate: this.FromDate
        };

        await mobXStoreHelper(APIS.CALC_PORTFOLIO_RETURN, params, (response) => {
            const portfolioReturnData = response.message.Return.shift().GroupDetails[0];
            const groupReturnData = response.message.Return.shift().GroupDetails.map((el) => ({
                ...el,
                Group_ID: el.GroupName
            }));

            const merged = mergeArrays(response.message.Return, groupReturnData, "Group_ID");

            const rows = [
                {
                    ...portfolioReturnData,
                    id: uuidv4(),
                    ID: portfolioReturnData.PortfolioName,
                    name: portfolioReturnData.PortfolioName,
                    Group_ID: "Portfolio",
                    variant: "portfolio"
                }
            ];
            merged.forEach((el) => {
                rows.push({
                    ...el,
                    ID: el.GroupName,
                    variant: "group",
                    id: uuidv4(),
                    name: el.Group_ID
                });

                if (el.GroupDetails && el.GroupDetails.length > 0) {
                    el.GroupDetails.forEach((el2) => {
                        rows.push({
                            ...el2,
                            ID: el2.AssetID,
                            variant: "asset",
                            id: uuidv4(),
                            name: el2.AssetName
                        });
                    });
                }
            });

            const IsHolding = response.message.IsHolding === 0 ? false : true;

            runInAction(()=>{
                this.calculatedStartDate = response.message.StartDate[0];
                this.calculatedEndDate = response.message.EndDate;

                this.IsHolding = IsHolding;
                this.AllRows = rows;
                
                this.DisplayRows = IsHolding || !this.IsExpanded ? rows : rows.filter((el) => {
                    return (
                        (el.UltimoHolding && el.UltimoHolding > 0) ||
                        el.variant === "group" ||
                        el.variant === "portfolio"
                    );
                });
                
                if (response.message.RemovedAssets) {
                    this.NonIncludedAssets = response.message.RemovedAssets.map((el) => {
                        return {
                            ...el,
                            id: el.AssetID,
                            Currency: el.AssetCurrencyCode,
                            description: translateErrorMessage(el.ErrorMessage),
                            AssetCurrencyCode: el.AssetCurrencyCode
                        };
                    });
                }

                this.loading = false;
                this.ReturnTable = response.message.Return;
            })
        });
    }

    updatePortfolioReturnDates(FromDate, ToDate) { // doesn't look like this is used
        this.FromDate = FromDate;
        this.ToDate = ToDate;
        this.earliestFromDate = FromDate;
        this.latestFromDate = ToDate;
        this.earliestToDate = FromDate;
        this.latestToDate = ToDate;
    }

    setReturnDate(CustomerID, PortfolioName, date, type) {
        if (date === null) {
            return;
        }

        if (date === this.FromDate && type === "From") {
            return;
        }

        if (date === this.ToDate && type === "To") {
            return;
        }

        runInAction(()=>{
            this.DisplayRows = [];
            this.AllRows = [];

            if (type === "To") {
                this.ToDate = date;
                this.latestFromDate = goDaysBack(date, 1);
                if (this.FromDate > this.latestFromDate) {
                    this.FromDate = this.latestFromDate;
                }
            } else {
                this.FromDate = date;
            }
        });

        // TODO: Calc return here
        this.calcPortfolioReturn(CustomerID, PortfolioName);
    }
}

const store = (window.portfolioReturnStore = new PortfolioReturnStore());

export default store;