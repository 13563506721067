import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { publicApiCall } from "api/call";
import HistoricalNav from "components/Charts/HistoricalNavChart";

const WesiteChartRenderer = () => {
    const [loading, setLoading] = React.useState(false);
    const [chartData, setChartData] = React.useState(null);
    const [error, setError] = React.useState(null);
    const location = useLocation();

    // Parse the query parameters
    const searchParams = new URLSearchParams(location.search);

    const uid = searchParams.get("uid");
    const key = searchParams.get("key");
    const chartid = searchParams.get("chartid");

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await publicApiCall(
                `/public-data/key=${key}&uid=${uid}`
            ); // (`/public-data/${key}/${uid}/${chartid}`)
            if (response.status === 200) {
                setChartData(response.data);
                setLoading(false);
                setError(null);
            } else {
                setError("Something went wrong while fetching chart data");
                setChartData(null);
                setLoading(false);
            }
            // try {

            //     const response = await fetch(`${IRISK_BO_API_URL}/public-data/${key}/${uid}/${chartid}`);
            //     debugger
            //     if (!response.ok) {
            //         throw new Error('Network response was not ok');
            //     }
            //     const data = await response.json();
            //     setChartData(data);
            //     setError(null);
            //     setTimeout(() => {
            //         console.log("Fetching chart with id", chartid, "for user", uid, "using key", key);
            //         setLoading(false);
            //         setChartData({ name: "Chart 1", data: [1, 2, 3, 4, 5] });
            //         setError(null);
            //     }, 3500);
            // } catch (error) {
            //     console.error("Error fetching chart data", error);
            //     setError("Something went wrong while fetching chart data");
            //     setChartData(null);
            //     setLoading(false);
            // }
        };
        fetchData();
    }, [uid, key, chartid]);

    // // Access individual query parameters
    // const uid = searchParams.get("uid");
    // const key = searchParams.get("key");
    // const chartid = searchParams.get("chartid");
    return (
        <div>
            {loading ? (
                <div>Indlæser...</div>
            ) : error ? (
                <div style={{ color: "red" }}>
                    Fejl ved indlæsning af graf data: {error}
                </div>
            ) : chartData ? (
                <div>
                    <HistoricalNav
                        isPubliclyEmbedded={true}
                        PortfolioName="Peruma"
                        CustomerID={125}
                        OrganisationID={13651}
                    />
                    {/* <HistoricalNav  PortfolioName="Wealth Invest Optimal VerdensIndex Moderat" CustomerID={200} OrganisationID={13651} /> */}
                    {/* {chartData.name}: {chartData.points.map((el) => (<p key={el}>{el},</p>))} */}
                </div>
            ) : (
                <div>Der er ingen graf data tilgængelig</div>
            )}
        </div>
    );
};

export default WesiteChartRenderer;
