import call from 'api/call';
import { APIS } from 'api/constants';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { getTodayByNumber } from "utils/dateFormatters";
import snackbar from "./snackbarStore";

class DataStore {
    currencyCodes = JSON.parse(localStorage.getItem("currencyCodes")) || [];
    currencyCode = localStorage.getItem("currencyCode") || "DKK";
    calcDate = getTodayByNumber();
    
    constructor() {
        makeObservable(this, {
            currencyCodes: observable,
            currencyCode: observable,
            setCurrencyCode: action.bound,
            fetchCurrencyCodes: action.bound,
            setCalcDate: action.bound,
            calcDate: observable,
        });
    }

    setCurrencyCode(value) {
        this.currencyCode = value;
        localStorage.setItem("currencyCode", value);
    }

    setCalcDate(newCalcDate) {
        runInAction(() => {
            this.calcDate = newCalcDate;
        });
    }

    async fetchCurrencyCodes() {
        try {
            const response = await call(APIS.GET_CURRENCY_CODE_LIST);

            if (response.success) {
            
                runInAction(() => {
                    localStorage.setItem("currencyCodes", JSON.stringify(response.message.CurrencyCodes))
                    this.currencyCodes = response.message.CurrencyCodes
                })
            } else {    
                snackbar.handleOpen("Unable to fetch currency codes", "error")
                // Sentry.captureException(new Error(response.meta.data.ErrorText));
            }
        } catch(error) {
            snackbar.handleOpen("Unable to fetch currency codes", "error")
            // Sentry.captureException(new Error(error));
        }
    }
}


const dataStore = (window.dataStore = new DataStore());

export default dataStore;
