/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { IconButton } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Card from "components/Card/Card";
import { exportSvgAsPng } from "utils/exports";

const ChartActions = ({id}) => {
    return (
        <IconButton size='small' onClick={() => exportSvgAsPng(id)}>
            <FileDownloadIcon fontSize='small' /> 
        </IconButton>
    )
}

const Widget = ({ headline, exportableData, renderActions, children, id }) => {
    return (
        <Card headline={headline} renderActions={() => [renderActions ? renderActions() : null, exportableData ? <ChartActions key={id} id={id} /> : null]}>
            {children}
        </Card>
    );
};
 
export default Widget;