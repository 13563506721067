/* eslint-disable no-undef */
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import ReactDOM from "react-dom/client";
import Snackbar from "Snackbar/Snackbar";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { theme } from './theme';
// import * as Sentry from "@sentry/react";
// import markerSDK from '@marker.io/browser';
import { LicenseInfo } from '@mui/x-license';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import moment from 'moment';
import 'moment/locale/da'; // Import Danish locale

moment.locale('da');


LicenseInfo.setLicenseKey("bc6309f5e2c7e3a8ff6330b56e38c518Tz04Njg4MixFPTE3NDMwMDkyOTMwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

// Sentry.init({
//   dsn: "https://a9335dd54cedcc5bcc8b8dcdc354d62e@o1301158.ingest.us.sentry.io/4508104056963072",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.browserProfilingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: [
//     "localhost", 
//     "https://staging.rebus-finance.com", 
//     "https://app.rebus-finance.com"
//   ],
//   // Set profilesSampleRate to 1.0 to profile every transaction.
//   // Since profilesSampleRate is relative to tracesSampleRate,
//   // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
//   // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
//   // results in 25% of transactions being profiled (0.5*0.5=0.25)
//   profilesSampleRate: 1.0,
//   // Session Replay
//   replaysSessionSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const app = document.getElementById('root');
const root = ReactDOM.createRoot(app);

const Base = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const isIframeEmbedded = searchParams.get("nobg") === "1" ? true : false; 

  // remove bg for iframes
  theme.palette.background.default = isIframeEmbedded ? "rgba(0,0,0,0)" : theme.palette.background.default; 

  return <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"da"}>

      <CssBaseline />
      {/* <GlobalLoader /> */}
      <Snackbar />
      <App />
    </LocalizationProvider>
  </ThemeProvider>
}

root.render(<Base />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
