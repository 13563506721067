import moment from "moment";
import { isNumber, isValidDate, isString } from "utils/typeChecker"

moment.locale('dk');

class RebusDate extends Date {
    constructor(date) {
        this.date = date;
    }

    asInt() {

    }

    asMomentDate() {

    }

    asFormattedString() {
        
    }
}

export const getPreviousWorkday = (date = undefined) => {
    let workday = moment();

    if(date) {
        workday = date
    }
    let day = workday.day();
    let diff = 5;  // returns yesterday
    if (day === 0 || day === 1){  // is Sunday or Monday
        diff = day + 2;  // returns Friday
    }

    const previousWorkday = workday.subtract(diff, 'days');

    // will return previous working day in digit format
    return Number(previousWorkday.format("YYYYMMDD"))
}

export const dateIntToDate = (dateInt) => {
    const year = parseInt(dateInt.toString().substring(0,4), 10)
    const month = parseInt(dateInt.toString().substring(4,6), 10) - 1
    const day = parseInt(dateInt.toString().substring(6,8), 10)


    const newDate = new Date(year, month, day);

    return newDate 
}

export const getCurrentYear = () => {
    return new Date().getFullYear()
} 

export const getDateRangeByNumberFromPeriod = (period = "YTD", endDateAsNumber = getTodayByNumber(), asString = false) => {
    let endDate = endDateAsNumber;
    let startDate;

    // "monthly", "quarterly", "yearly", "YTD"
    if(period === "YTD") {
        startDate = parseInt(moment().startOf('year').format("YYYYMMDD")) // First day of the year
    } else if(period === "yearly") {
        startDate = parseInt(moment().subtract(365, 'days').format("YYYYMMDD")) // 365 days back
    } else if(period === "quarterly") {
        startDate = parseInt(moment().subtract(3, 'months').format("YYYYMMDD")) // 3 months back
    } else if(period === "monthly") {
        startDate = parseInt(moment().subtract(1, 'months').format("YYYYMMDD")) // 1 month back
    }

    if(asString) {
        return `${formattedDateToString(startDate)} - ${formattedDateToString(endDate)}`
    }

    return {
        FromDate: startDate,
        ToDate: endDate
    }
}


export const formattedDateToString = (payloadDate, format = "DD MMM YY", showTime = false) => {
    let date;
    let time;

    if(isValidDate(payloadDate)) {
        date = moment(payloadDate)
        time = moment(payloadDate)
    } else if(Number.isInteger(payloadDate)) {
        date = moment(dateIntToDate(payloadDate))
        time = moment(dateIntToDate(payloadDate))
    } else if(payloadDate === undefined) {
        date = now()
        time = now()
    } else if(isValidDate(new Date(payloadDate))) {
        date = moment(payloadDate)
        time = moment(payloadDate)
    } else {
        date = new Date()
    }

    if(date && date.format) {
        const formattedDate = date.format(format);
        
        let val = `${formattedDate}`
        
        if(showTime && time) {
            const formattedTime = time.format("HH:mm")
            val += ` - ${formattedTime}`
        }
    
        return val
    }

    return ""
}

export const getDateByNumber = (unit = 1, timeType = "months") => {
    const past = moment().subtract(unit, timeType)

    let day = past.day();
    let diff = 5;  // returns yesterday
    if (day === 0 || day === 1){  // is Sunday or Monday
        diff = day + 2;  // returns Friday
    }

    const previousWorkday = past.subtract(diff, 'days');

    // will return previous working day in digit format
    return Number(previousWorkday.format("YYYYMMDD"))
}

export const getFirstDayOfYear = () => {
    let date = new Date();
    return new Date(date.getFullYear(), 0, 1);
}

export const getTodayByNumber = () => {
    const today = moment()
    const value = Number(today.format("YYYYMMDD"))

    return value
}

export const goYearsBack = (intDate, yearsBack = 1) => {
    const yearsFromNow = intDate - (10000 * yearsBack)
    
    return yearsFromNow
}

export const goMonthsBack = (intDate, months = 1) => {
    return parseInt(moment(dateIntToDate(intDate)).subtract(months, "months").format("YYYYMMDD"));
}

export const goWeeksBack = (intDate, weeks = 1) => {
    return parseInt(moment(dateIntToDate(intDate)).subtract(weeks, "weeks").format("YYYYMMDD"));
}

export const goDaysBack = (intDate, days = 1) => {
    return parseInt(moment(dateIntToDate(intDate)).subtract(days, "days").format("YYYYMMDD"));
}

export const dateToInt = (date) => {
    let momentDate;
    if(moment.isMoment(date)) {
        momentDate = moment(date)
    } else if(isNumber(date) && date.toString().length === 8) {
        return date
    } else if (isValidDate(date)) {
        momentDate = moment(date);
    } else if(isString(date)) {
        momentDate = moment(new Date(date))
    } else {
        throw Error("invalid format provided to dateToInt", date)
    }

    const year = momentDate.year();
    const month = ('0' + (momentDate.month() + 1)).slice(-2)
    const day = momentDate.date();

    const formattedDate = day < 10 ? ('0' + momentDate.date()) : day;

    // convert to int (yyyymmdd)
    return parseInt(`${year}${month}${formattedDate}`)
}

export const stringToInt = (str) => {
    return dateToInt(moment(str))
}

export const now = () => {
    return moment()
}

export const constructDatesList = (timeline = "past") => {

    const now = moment();
    if(timeline === "future") {
        const today = dateToInt(now)
        const oneMonthAhead = dateToInt(now.add(1, 'months'))
        const twoMonthsAhead = dateToInt(now.add(2, 'months'))
        const threeMonthsAhead = dateToInt(now.add(3, 'months'))
        const sixMonthsAhead = dateToInt(now.add(6, 'months'))

        return [{
            value: today,
            label: "Today"
        }, {
            value: oneMonthAhead,
            label: "1 month from now"
        }, {
            value: twoMonthsAhead,
            label: "2 months from now"
        }, {
            value: threeMonthsAhead,
            label: "3 months from now"
        }, {
            value: sixMonthsAhead,
            label: "6 months from now"
        }]
    }

    const today = dateToInt(now)
    const sixMonthsAgo = dateToInt(now.subtract(6, 'months'))
    const oneYearAgo = dateToInt(now.subtract(1, 'years'))
    const twoYearsAgo = dateToInt(now.subtract(2, 'years'))
    const threeYearsAgo = dateToInt(now.subtract(3, 'years'))
    const fiveYearsAgo = dateToInt(now.subtract(5, 'years'))
    const tenYearsAgo = dateToInt(now.subtract(10, 'years'))

    return [{
        value: today,
        label: "Today"
    }, {
        value: sixMonthsAgo,
        label: "6 Months ago"
    }, {
        value: oneYearAgo,
        label: "1 year ago"
    }, {
        value: twoYearsAgo,
        label: "2 years ago"
    }, {
        value: threeYearsAgo,
        label: "3 years ago"
    }, {
        value: fiveYearsAgo,
        label: "5 years ago"
    }, {
        value: tenYearsAgo,
        label: "10 years ago"
    }]
}
