import { toPng } from 'html-to-image';

export const exportSvgAsPng = (id) => {
    const element = document.getElementById(id);

    const options = {
      pixelRatio: 2 // Increase pixel ratio for better quality
  };

    toPng(element, options)
    .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `${id}.png`;
        link.href = dataUrl;
        link.click();
    })
    .catch((err) => {
        console.log('An error occurred while downloading the PNG image:', err);
    });
}