import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import React from 'react';

const StyledCardFooter = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    flexDirection: "row-reverse",
    display: "flex",
}))

const CardFooter = (props) => {
    return (
        <StyledCardFooter>
            {props.children}
        </StyledCardFooter>
    );
}

CardFooter.propTypes = {
    children: PropTypes.node,
}
 
export default CardFooter;