import React from "react";
import { Bar, BarChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';
import { colors, chartColorsArray } from "styles/variables";
import { numberWithCommas } from "utils/numberManipulators";
import PropTypes from 'prop-types';
import { formattedDateToString } from "utils/dateFormatters"
import { calculateInterval, domain, getValueInUnit } from './helpers';

const DECIMAL_PLACES = 3

export const calculateDomain = (data, key) => {
    if (!data || data.length === 0) {
        return [0, 0]; // Return a default domain if no data
    }

    const values = data.map(item => item[key]).filter(value => value !== undefined); // Extract values for the specified key
    const min = Math.min(...values); // Calculate the minimum value
    const max = Math.max(...values); // Calculate the maximum value

    // Add some padding to the domain
    const padding = (max - min) * 0.1; // 10% padding
    return [min - padding, max + padding]; // Return the domain with padding
};

const EnhancedBarChart = ({data, dataKeys: [x, y, unit], xAxisLabelType}) => {
    
    return (
        <ResponsiveContainer height={"100%"}>
            <BarChart data={data} margin={{ right: 20, left: 20 }}>
                <XAxis
                    // interval={calculateInterval(data)}
                    dataKey={x}
                    tickFormatter={(value) => {
                        if(xAxisLabelType === "string") {
                            return value
                        }

                        return `${formattedDateToString(value)}`
                    }}
                    tickLine={false}
                    orientation='bottom'
                    padding={{ left: 0, right: 0 }}
                />
                <YAxis
                    domain={calculateDomain(data, y)}
                    orientation='left'
                    type="number"
                    tickLine={false}
                    tickFormatter={(value) => {
                        return unit ? getValueInUnit(value, unit) : numberWithCommas(value, DECIMAL_PLACES)
                    }}
                    yAxisId={"left"}
                />

                <Bar
                    // formatter={(v) => numberWithCommas(v, 2)}
                    dataKey={y}
                    stroke={chartColorsArray[0]}
                    yAxisId="left"
                    formatter={(v) => `${numberWithCommas(v)} ${unit}`}
                />
                <Tooltip
                    labelFormatter={(value) => {
                        if(xAxisLabelType === "string") {
                            return value
                        }
                        return `${formattedDateToString(value)}`;
                    }}
                    formatter={(value, label) => {
                        return [`${numberWithCommas(value, 2)}`, "value"]
                    }}
                />
                <Legend />
                {/* {hasYRightDataKeys && yRightDataKeys.map((yRightDataKeySet, index) => {
                    const [xSet, ySet, altUnit] = yRightDataKeySet
                    return (
                        <>
                            <YAxis
                                domain={calculateDomain(data, ySet)}
                                orientation='right'
                                type="number"
                                tickLine={false}
                                tickFormatter={(value) => {
                                    return altUnit ? `${getValueInUnit(value, altUnit)}${altUnit}` : numberWithCommas(value, 2)
                                }}
                                yAxisId={"right"}
                            >
                                <Label
                                    value={yRightAxisLabel}
                                    angle={-90}
                                    position="outsideCenter"
                                    dx={40}
                                    dy={0}
                                />
                            </YAxis>
                            <XAxis
                                hide
                                dataKey={xSet}
                                interval={interval}
                            />
                            <Line
                                key={index}
                                dot={null}
                                activeDot={{ r: 8 }}
                                type="monotone"
                                dataKey={ySet}
                                stroke={chartColorsArray[index + 1]}
                                yAxisId="right"
                                opacity={0.5}
                            />
                        </>
                    )
                })} */}


                {/* <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                <ReferenceLine y={0.1} stroke="#000" />
                {Array.isArray(y) ? y.map((barDataKey, index) => {
                    return (
                        <Bar key={barDataKey} dataKey={barDataKey} fill={chartColorsArray[index]} formatter={(v) => `${numberWithCommas(v)} ${unit}`} />
                    )
                }) : (
                    <Bar dataKey={y} fill={chartColorsArray[0]} formatter={(v) => `${numberWithCommas(v)} ${unit}`} />
                )} */}
            </BarChart>
        </ResponsiveContainer>
    )
}

EnhancedBarChart.propTypes = {
    data: PropTypes.array,
    dataKeys: PropTypes.array,
    unit: PropTypes.string,
    height: PropTypes.number,
    xAxisLabelType: PropTypes.string,
}



export default EnhancedBarChart
