/* eslint-disable react/prop-types */
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const StyledCardHeadline = styled(Box)(({ theme }) => ({
    display: "flex",
    flex: 1,
    marginRight: "18px"
}))

const CardHeadline = (props) => {
    return (
        <StyledCardHeadline>
            <Typography variant="h6" fontWeight={"bold"}>{props.headline}</Typography>
        </StyledCardHeadline>
    )
}

export default CardHeadline;