import React from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';
import PropTypes from 'prop-types';
import { formattedDateToString } from "utils/dateFormatters"
import { numberWithCommas } from 'utils/numberManipulators';
import { domain, setNumOfIntervalLabels, getValueInUnit } from './helpers';
import { chartColorsArray } from "styles/variables";
import './charts.css'

const DECIMAL_PLACES = 3
const numberOfLabels = 6;

export const calculateDomain = (data, key) => {
    if (!data || data.length === 0) {
        return [0, 0]; // Return a default domain if no data
    }

    const values = data.map(item => item[key]).filter(value => value !== undefined); // Extract values for the specified key
    const min = Math.min(...values); // Calculate the minimum value
    const max = Math.max(...values); // Calculate the maximum value

    // Add some padding to the domain
    const padding = (max - min) * 0.1; // 10% padding
    return [min - padding, max + padding]; // Return the domain with padding
};

const LineChartComponent = ({data, dataKeys: [x, y, unit], yRightDataKeys = [], yRightAxisLabel, yLeftAxisLabel}) => {
    const interval = setNumOfIntervalLabels(data.length, numberOfLabels);
    const hasYRightDataKeys = yRightDataKeys.length
    return (
        <ResponsiveContainer height="100%">
            <LineChart data={data} margin={{ right: 20, left: 20 }}>
                <XAxis
                    dataKey={x}
                    tickFormatter={(value) => `${formattedDateToString(value)}`}
                    interval={interval}
                    tickLine={false}
                    orientation='bottom'
                    padding={{ left: 0, right: 0 }}
                />
                <YAxis
                    domain={calculateDomain(data, y)}
                    orientation='left'
                    type="number"
                    tickLine={false}
                    tickFormatter={(value) => {
                        return unit ? getValueInUnit(value, unit) : numberWithCommas(value, DECIMAL_PLACES)
                    }}
                    yAxisId={"left"}
                >
                    <Label
                        value={yLeftAxisLabel}
                        angle={-90}
                        position="outsideCenter"
                        dx={-40}
                        dy={0}
                    />
                </YAxis>
                <Line
                    dot={null}
                    activeDot={{ r: 8 }}
                    type="monotone"
                    // formatter={(v) => numberWithCommas(v, 2)}
                    dataKey={y}
                    stroke={chartColorsArray[0]}
                    yAxisId="left"
                />
                <Tooltip
                    labelFormatter={(value) => {
                        return `${formattedDateToString(value)}`;
                    }}
                    formatter={(value, label, dome) => {
                        return [`${numberWithCommas(value, 2)}`, label]
                    }}
                />
                <Legend />
                {hasYRightDataKeys && yRightDataKeys.map((yRightDataKeySet, index) => {
                    const [xSet, ySet, altUnit] = yRightDataKeySet
                    return (
                        <>
                            <YAxis
                                domain={calculateDomain(data, ySet)}
                                orientation='right'
                                type="number"
                                tickLine={false}
                                tickFormatter={(value) => {
                                    return altUnit ? `${getValueInUnit(value, altUnit)}${altUnit}` : numberWithCommas(value, 2)
                                }}
                                yAxisId={"right"}
                            >
                                <Label
                                    value={yRightAxisLabel}
                                    angle={-90}
                                    position="outsideCenter"
                                    dx={40}
                                    dy={0}
                                />
                            </YAxis>
                            <XAxis
                                hide
                                dataKey={xSet}
                                interval={interval}
                            />
                            <Line
                                key={index}
                                dot={null}
                                activeDot={{ r: 8 }}
                                type="monotone"
                                dataKey={ySet}
                                stroke={chartColorsArray[index + 1]}
                                yAxisId="right"
                                opacity={0.5}
                            />
                        </>
                    )
                })}
            </LineChart>
        </ResponsiveContainer>
    );
};

LineChartComponent.propTypes = {
    data: PropTypes.array,
    dataKeys: PropTypes.array,
    multiline: PropTypes.bool,
    yRightDataKeys: PropTypes.array,
    yRightAxisLabel: PropTypes.string,
    yLeftAxisLabel: PropTypes.string,
}

export default LineChartComponent;
